import { baseURLProduction, baseURLStaging } from "../../../global-constants";
import { callApi, goToScreen } from "../../../partials/pages/home";

export default class Avatar {
  constructor() { }
  init() {
    this.baseUrl = baseURLProduction; // switch ENV
    const genderOptionBtn = document.querySelector(".js-gender-btn");
    const customizationOptions = document.querySelectorAll(
      ".js-customization-options"
    );
    const genderRadios = document.querySelectorAll('input[name="gender"]');
    let avatarContainer = document.querySelectorAll(".avatar-image");
    genderOptionBtn.disabled = true;
    const female = document.querySelector(".female");
    const male = document.querySelector(".male");
    const avatarIntroduction = document.querySelector(
      ".js-avatar-introduction"
    );
    genderRadios.forEach((radio) => {
      radio.addEventListener("click", () => {
        genderOptionBtn.disabled = false;
        genderRadios.forEach((otherRadio) => {
          otherRadio.parentElement.classList.remove("select");
        });
        radio.parentElement.classList.toggle("select");
      });
    });

    genderOptionBtn.addEventListener("click", (e) => {
      const selectedGender = document.querySelector(
        'input[name="gender"]:checked'
      ).value;
      const avatarWrapper = document.querySelector(
        ".js-avatar-introduction-inner"
      );
      avatarWrapper.classList.add("hide");
      customizationOptions.forEach((option) => {
        option.classList.remove("active");
        if (option.classList.contains(selectedGender)) {
          option.classList.add("active");
          if (selectedGender === "male") {
            selectRandomFeatures(male);
          } else if (selectedGender === "female") {
            selectRandomFeatures(female);
          }
        } else {
          option.style.display = "none";
        }
      });

      e.target.classList.add("hide");

      let generateButton = `
        <button class="btn btn--primary btn--icon js-generate" data-bgxs="src/assets/img/hemofarm-building-mobile-background-mob.png">
          <span class="btn__text">
          Započni svoju virtuelnu turu 
            <span class="btn__icon">
              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 8 12" width="8" height="12" fill="none"><path stroke="#fff" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="m1.5 1 5 5-5 5"></path></svg>
            </span>
          </span>
        </button>`;

      let configuratorWrapper = document.querySelector(
        ".js-configurator-wrapper"
      );

      configuratorWrapper.insertAdjacentHTML("beforeend", generateButton);
      function getParents(element, selector) {
        var parents = [];
        var currentElement = element.parentElement;

        while (currentElement !== null) {
          if (selector) {
            if (currentElement.matches(selector)) {
              parents.push(currentElement);
            }
          } else {
            parents.push(currentElement);
          }

          currentElement = currentElement.parentElement;
        }

        return parents;
      }
      document.querySelector(".js-generate").addEventListener("click", async (e) => {
        const avatarImages = document.querySelectorAll(".avatar-image img");
        const qoutes = document.querySelector(".qoutes");
        avatarImages.forEach((image) => {
          image.parentNode.removeChild(image);
        });
        document.querySelector(".avatar-placeholder").classList.add("active");
        qoutes.classList.add("active");
        configuratorWrapper.classList.add("hide");
        let base64 = imagetoBase64(avatarImages);
        localStorage.setItem("avatarBase64", base64);

        // update avatar image in db 
        // @todo add authorization to update avatar route
        let user = JSON.parse(localStorage.getItem('user'));

        user.avatar_image = base64;
        localStorage.setItem('user', JSON.stringify(user));

        let response = await callApi(
          `${this.baseUrl}/users/${user.id}`,
          "PATCH",
          {
            avatar_image: base64
          },
          false
        );

        if (response) {

          let avatarCreatePlaceholder = document.querySelectorAll(
            ".js-avatar-placeholder"
          );
          avatarCreatePlaceholder.forEach((item) => {
            setAvatarPlaceholder(item);
          });

          avatarIntroduction.classList.add("hide");

        }

        if (window.innerWidth < 768) {
          if (e.target.dataset.bgxs != undefined) {
            let bg = e.target.dataset.bgxs;
            let parents = getParents(e.target, ".screen-bg");

            parents.forEach((parent) => {
              let parentBgImg = parent.querySelector(".bg");
              parentBgImg.style.backgroundImage = `url(${bg})`;
              parentBgImg.style.height = "44%";
            });
          }
        }
      });

      function setAvatarPlaceholder(selector) {
        setTimeout(() => {
          let getAvatarBase64 = localStorage.getItem("avatarBase64");

          // Create a new img element
          const imgElement = document.createElement("img");

          // Set its src attribute to the stored base64 image data
          imgElement.src = getAvatarBase64;

          // Clear the inner HTML of the selector
          selector.innerHTML = "";

          // Append the new img element to the selector
          selector.appendChild(imgElement);
        }, 300);
      }

      function imagetoBase64(img) {
        let c = document.createElement("canvas");

        c.height = img[0].naturalHeight;
        c.width = img[0].naturalWidth;
        let ctx = c.getContext("2d");

        img.forEach(function (image, index) {
          ctx.drawImage(image, 0, 0, c.width, c.height);
        });

        return c.toDataURL();
      }
    });

    const skinOptions = document.querySelectorAll(
      '.skin-option input[type="radio"]'
    );
    let hairOptions = document.querySelectorAll(
      '.hair-option input[type="radio"]'
    );
    const hairColorOptions = document.querySelectorAll(
      '.hair-color-option input[type="radio"]'
    );
    const clothingOptions = document.querySelectorAll(".clothing-option input");

    const pantsOptions = document.querySelectorAll(
      '.pants-option input[type="radio"]'
    );
    const accessoriesOptions = document.querySelectorAll(
      '.accessories-option input[type="radio"]'
    );

    function selectRandomFeatures(selectedGender) {
      switch (selectedGender) {
        case male:
          createAndAppendImage(
            avatarContainer,
            skinOptions[4].dataset.image,
            "skin-img"
          );
          createAndAppendImage(
            avatarContainer,
            hairOptions[5].dataset.image,
            "hair-img"
          );
          createAndAppendImage(
            avatarContainer,
            pantsOptions[5].dataset.image,
            "pants-img"
          );
          createAndAppendImage(
            avatarContainer,
            clothingOptions[5].dataset.image,
            "clothing-img"
          );
          createAndAppendImage(
            avatarContainer,
            accessoriesOptions[5].dataset.image,
            "accessories-img"
          );

          break;
        case female:
          createAndAppendImage(
            avatarContainer,
            skinOptions[1].dataset.image,
            "skin-img"
          );
          createAndAppendImage(
            avatarContainer,
            hairOptions[1].dataset.image,
            "hair-img"
          );
          createAndAppendImage(
            avatarContainer,
            pantsOptions[1].dataset.image,
            "pants-img"
          );
          createAndAppendImage(
            avatarContainer,
            clothingOptions[1].dataset.image,
            "clothing-img"
          );

          createAndAppendImage(
            avatarContainer,
            accessoriesOptions[1].dataset.image,
            "accessories-img"
          );
          break;
      }

      function createAndAppendImage(containers, src, className) {
        containers.forEach((container) => {
          const img = document.createElement("img");
          img.setAttribute("src", src);
          img.classList.add(className);
          container.appendChild(img);
        });
      }
    }

    // Skin color
    skinOptions.forEach((option) => {
      option.addEventListener("click", function () {
        skinOptions.forEach((option) => {
          option.parentNode.classList.remove("selected");
        });
        this.parentNode.classList.add("selected");

        const prevSkinImgs = document.querySelectorAll(
          ".avatar-image img.skin-img"
        );
        prevSkinImgs.forEach((img) => {
          img.remove();
        });

        const skinImg = document.createElement("img");
        skinImg.classList.add("skin-img");
        skinImg.setAttribute("src", this.dataset.image);

        avatarContainer.forEach((item) => {
          let clonedImg = skinImg.cloneNode(true); // Clone the skinImg element
          item.prepend(clonedImg);
        });
      });
    });

    // Create and add new hair image
    let hairImg = document.createElement("img");
    hairImg.classList.add("hair-img");

    // Hair styles variations
    hairOptions.forEach((option) => {
      option.addEventListener("click", function () {
        hairOptions.forEach((option) => {
          option.parentNode.classList.remove("selected");
        });

        this.parentNode.classList.add("selected");

        let styleAndColor;

        hairColorOptions.forEach((hairColorOption) => {
          if (hairColorOption.classList.contains("selected")) {
            let selectedStyle = this.dataset.image.split("-color-");
            let selectedColor = hairColorOption.dataset.color;
            styleAndColor = selectedStyle[0] + "-" + selectedColor + ".png";

            hairImg.setAttribute("src", "");
            hairImg.setAttribute("src", styleAndColor);
          }
        });

        if (styleAndColor === undefined) {
          hairImg.setAttribute("src", this.dataset.image);
        }

        avatarContainer.forEach((item) => {
          let imgHair = hairImg.getAttribute("src");

          const prevHairImg = document.querySelectorAll(
            ".avatar-image img.hair-img"
          );
          prevHairImg.forEach((img) => {
            img.setAttribute("src", imgHair);
          });
        });
      });
    });

    // Hair color variations

    hairColorOptions.forEach((option) => {
      option.addEventListener("click", function () {
        hairColorOptions.forEach((options) => {
          options.classList.remove("selected");
        });

        hairColorOptions.forEach((option) => {
          option.parentNode.classList.remove("selected");
        });

        this.parentNode.classList.add("selected");
        this.classList.add("selected");
        let styleAndColor;
        avatarContainer.forEach((item) => {
          hairOptions.forEach((hairOption) => {
            if (hairOption.parentNode.closest('.avatar-introduction__customization-options').classList.contains('active')) {
              console.log(hairOption);
              if (hairOption.parentNode.classList.contains("selected")) {
                let selectedStyle = hairOption.dataset.image.split("-color-");
                let selectedColor = this.dataset.color;
                styleAndColor = selectedStyle[0] + "-" + selectedColor + ".png";
                hairImg.setAttribute("src", "");
                hairImg.setAttribute("src", styleAndColor);
              }
            }
          });
          const prevHairImg = document.querySelectorAll(
            ".avatar-image img.hair-img"
          );
          prevHairImg.forEach((img) => {
            img.setAttribute("src", styleAndColor);
          });
        });
      });
    });

    // Clothes
    clothingOptions.forEach((option) => {
      option.addEventListener("click", function () {
        // remove previously selected clothing image
        clothingOptions.forEach((option) => {
          option.parentNode.classList.remove("selected");
        });
        this.parentNode.classList.add("selected");

        const prevClothingImgs = document.querySelectorAll(
          ".avatar-image img.clothing-img"
        );
        prevClothingImgs.forEach((img) => {
          img.remove();
        });

        // create and add new clothing image
        const clothingImg = document.createElement("img");
        clothingImg.classList.add("clothing-img");
        clothingImg.setAttribute("src", this.dataset.image);

        avatarContainer.forEach((item) => {
          let pants = item.querySelector(".accessories-img");
          let clonedImg = clothingImg.cloneNode(true);
          item.insertBefore(clonedImg, pants);
        });
      });
    });

    // Pants
    pantsOptions.forEach((option) => {
      option.addEventListener("click", function () {
        pantsOptions.forEach((option) => {
          option.parentNode.classList.remove("selected");
        });
        this.parentNode.classList.add("selected");

        // remove previously selected hair image
        const prevPantsImgs = document.querySelectorAll(
          ".avatar-image img.pants-img"
        );
        prevPantsImgs.forEach((img) => {
          img.remove();
        });

        // create and add new hair image
        const pantsImg = document.createElement("img");
        pantsImg.classList.add("pants-img");
        pantsImg.setAttribute("src", this.dataset.image);
        // avatarContainer.appendChild(pantsImg);
        avatarContainer.forEach((item) => {
          let hair = item.querySelector(".clothing-img");
          let clonedImg = pantsImg.cloneNode(true);
          item.insertBefore(clonedImg, hair);
        });
      });
    });

    // Accessories
    accessoriesOptions.forEach((option) => {
      option.addEventListener("click", function () {
        accessoriesOptions.forEach((option) => {
          option.parentNode.classList.remove("selected");
        });
        this.parentNode.classList.add("selected");

        // remove previously selected hair image
        const prevAccessoriesImgs = document.querySelectorAll(
          ".avatar-image img.accessories-img"
        );
        prevAccessoriesImgs.forEach((img) => {
          img.remove();
        });

        // create and add new hair image
        const accessoriesImg = document.createElement("img");
        accessoriesImg.classList.add("accessories-img");
        accessoriesImg.setAttribute("src", this.dataset.image);
        // avatarContainer.appendChild(accessoriesImg);
        avatarContainer.forEach((item) => {
          let clothing = item.querySelector(".clothing-img");
          let clonedImg = accessoriesImg.cloneNode(true);
          item.insertAdjacentElement('beforeend', clonedImg);
        });
      });
    });


    // let activeLevel = null;

    function tabMobile() {
      let btnTabMobile = document.querySelectorAll(".js-tab");
      btnTabMobile.forEach((trigger) => {
        trigger.addEventListener("click", (e) => {
          let target = e.currentTarget;

          let tab = target.dataset.tab;
          let tabElement = document.querySelector(`.js-tab-${tab}`);
          let tabs = document.querySelectorAll(".js-tab-item");

          if (!target.classList.contains("is-active")) {
            btnTabMobile.forEach((btnMobItem) => {
              if (btnMobItem.classList.contains("is-active")) {
                btnMobItem.classList.remove("is-active");
                target.classList.add("is-active");
              }
            });

            trigger.classList.remove("is-active");
            target.classList.add("is-active");
            tabs.forEach((tabItem) => {
              if (tabItem.classList.contains("is-active")) {
                tabItem.classList.remove("is-active");
                tabElement.classList.add("is-active");
              }
            });
          }
        });
      });
    }

    if (window.innerWidth < 991) {
      tabMobile();
    }
  }
}
