import { baseURLProduction, baseURLStaging } from "../../../global-constants";
import { callApi, goToScreen } from "../../../partials/pages/home";

const userLocalStorage = localStorage.getItem("user")
  ? JSON.parse(localStorage.getItem("user"))
  : null;
const introLevelLocalStorage = localStorage.getItem("Intro")
  ? JSON.parse(localStorage.getItem("Intro"))
  : null;
const level1LocalStorage = localStorage.getItem("Level 1")
  ? JSON.parse(localStorage.getItem("Level 1"))
  : null;
const setCurrentLevelLocal = (level) => {
  localStorage.setItem("currentLevel", level);
  localStorage.setItem("level", level);
};

export default class App {
  constructor() {
    this.baseUrl = baseURLProduction; // switch ENV
    this.questionNumberEl = document.querySelector(".js-question-number");
    this.questionContainer = document.querySelector(".js-question-container");
    this.questionTimer = document.querySelector(".js-question-timer");
    this.questionEl = document.querySelector(".js-question");
    this.answerButtonsEl = document.querySelector(".js-answers");
    this.confirmButtonEl = document.querySelector(".js-confirm-button");
    this.resultContainer = document.querySelector(".js-result-container");
    this.resultEl = document.querySelectorAll(".js-result");
    this.nextButtonEl = document.querySelector(".js-next-button");
    this.nextButtonScreen = document.querySelectorAll(".js-nextBtn");
    this.questionInfo = document.querySelector(".js-question-info p");
    this.screenExitBtn = document.querySelector(".js-exit-screen");
    this.screenExitContent = document.querySelector(".js-exit-screen-content");
    this.screenExitQoutes = document.querySelectorAll(".js-qoutes-exit-screen");
    this.btnLevelDoor = document.querySelectorAll(".js-level");
    this.lottieTrigger = document.querySelector(".js-lottie-trigger");
    this.currentQuestionIndex;
    this.score = 0;
  }
  async init() {
    // this.generateRangList();
    this.stopTimer();
    this.resetGame();
    this.resultEl.forEach(async (item) => {
      item.innerText = `${this.getCurrentScore()}`;
    });
    this.currentQuestionIndex;
    let _this = this;
    _this.questionInfo.textContent = "";
    _this.questionInfo.className = "";

    this.confirmButtonEl.addEventListener("click", () => {
      _this.validateAnswer();
      this.nextButtonEl.disabled = false;
      this.confirmButtonEl.disabled = true;
    });

    this.nextButtonEl.addEventListener("click", () => {
      this.confirmButtonEl.disabled = false;
      _this.answerButtonsEl.querySelectorAll(".js-answer").forEach((button) => {
        button.classList.remove("correct", "incorrect");
        button.checked = false;
        button.disabled = false;
      });

      _this.questionInfo.textContent = "";
      _this.questionInfo.className = "";
      _this.showNextQuestion();
    });

    // upit da li u bazi ili localStorage-u postoji current level
    // let currentLevel = localStorage.getItem('level');

    // const categories = {
    //   'Intro': 1,
    //   'Level 1': 2,
    //   'Level 1': 2,
    // };

    if (localStorage.getItem("level") === "Intro") {
      if (!localStorage.getItem("avatarBase64")) {
        goToScreen("avatar-screen");
        return;
      }
      if (localStorage.getItem("currentQuestion")) {
        await _this.getQuestions(1, localStorage.getItem("currentQuestion"));
      } else {
        await _this.getQuestions(1);
      }
    }
    this.screenExit();
    this.isCompleted();
  }
  // async generateRangList() {
  //   let response = await callApi(`${this.baseUrl}/rang-list`, 'GET', {}, localStorage.getItem('access_token'));
  //   if (response.data) {
  //     let rangList = document.querySelector('#global-rang-list tbody');
  //     rangList.innerHTML = '';
  //     response.data.forEach((item, index) => {
  //       rangList.insertAdjacentHTML('beforeend', `<tr>
  //       <td>${++index}.</td>
  //       <td>${item.name}</td>
  //       <td>${item.score}</td>
  //     </tr>`);
  //     });
  //   }
  // }

  resetGame() {
    this.questionInfo.textContent = "";
    this.questionInfo.className = "";
  }
  showQuestion(question) {
    if (!question) {
      if (introLevelLocalStorage.progress === "completed") {
        this.showResult();
        return;
      }

      goToScreen("question-intro");
      return;
    }

    const categories = {
      1: "Intro",
      2: "Level 1",
      3: "Level 2",
      4: "Level 3",
      5: "Level 4",
      6: "Level 5",
    };
    // set current level and question in localstorage

    if (question && question.categoryID && question.questionNumber) {
      localStorage.setItem("currentLevel", categories[question.categoryID]);
      localStorage.setItem("currentQuestion", question.questionNumber);
      localStorage.setItem("currentQuestionAnswered", false);
    }

    let questionNumber = question.questionNumber;
    this.questionNumberEl.innerHTML = `Pitanje broj ${questionNumber}.`;

    this.questionEl.innerText = question.question;
    this.answerButtonsEl.innerHTML = "";
    if (
      question.categoryID === 1 ||
      question.categoryID === 2 ||
      question.categoryID === 3 ||
      question.categoryID === 4 ||
      question.categoryID === 5
    ) {
      this.screenQoutesExit();
    }
    if (question.categoryID === 1) {
      this.screenExit();
    }
    question.answers.forEach((answer, index) => {
      const div = document.createElement("div");
      div.classList.add("question__answer");
      const alphabetDiv = document.createElement("div");
      alphabetDiv.classList.add("question__answer-left");
      const alphabetSpan = document.createElement("h3");
      alphabetSpan.classList.add("h4");
      alphabetSpan.innerText = String.fromCharCode(65 + index);
      alphabetDiv.appendChild(alphabetSpan);
      div.appendChild(alphabetDiv);

      const questionDiv = document.createElement("div");
      questionDiv.classList.add("question__answer-right", "js-answer");

      const input = document.createElement("input");
      input.type = "radio";
      input.name = "answer";
      input.id = `answer-${question.id}-${index}`;
      input.value = answer;
      questionDiv.appendChild(input);
      div.appendChild(questionDiv);

      const label = document.createElement("label");
      label.innerText = answer;
      label.setAttribute("for", `answer-${question.id}-${index}`);
      questionDiv.appendChild(label);

      if (answer === question.correctAnswer) {
        input.dataset.correct = true;
      } else {
        input.dataset.correct = false;
      }
      this.confirmButtonEl.disabled = true;
      this.nextButtonEl.disabled = true;

      input.addEventListener("click", () => {
        input.checked = true;
        this.confirmButtonEl.disabled = false;
      });

      this.answerButtonsEl.appendChild(div);
    });
    this.startTimer(30);
    this.selectAnswer();
  }
  async showResult() {
    console.log("...");
    let _this = this;
    this.questionNumberEl.style.display = "none";
    this.questionEl.style.display = "none";
    this.questionTimer.style.display = "none";
    this.questionContainer.style.display = "none";
    document.querySelector(".question__question-cta").style.display = "none";
    this.questionInfo.style.display = "none";
    this.resultContainer.style.display = "block";
    this.resultContainer.parentElement.classList.add("results");

    this.resultEl.forEach(async (item) => {
      item.innerText = `${this.getCurrentScore()}`;

      // Retrieve the current completed quiz ID
      let currentDoorId = localStorage.getItem("currentLevel");

      // Set the completion status for the current quiz
      if (currentDoorId) {
        localStorage.setItem(`quizCompleted_${currentDoorId}`, true);

        let response = await callApi(
          `${this.baseUrl}/update-employee-progress`,
          "POST",
          {
            progress_name: currentDoorId,
            progress: "completed",
            // score: localStorage.getItem('currentScore')
          },
          localStorage.getItem("access_token")
        );
      }

      localStorage.removeItem("currentLevel");
    });
    let totalLevels = 6; // Assuming there are 10 levels in total

    let allQuizzesCompleted = true;

    // check progresses from DB
    let response = await callApi(
      `${this.baseUrl}/progress`,
      "GET",
      {},
      localStorage.getItem("access_token")
    );
    if (response) {
      let progresses = response.data;

      progresses.map((progress) => {
        if (progress.progress === "initial") {
          allQuizzesCompleted = false;
        }
      });
    }

    if (
      localStorage.getItem("quizCompleted_Level 1") &&
      localStorage.getItem("quizCompleted_Level 2") &&
      localStorage.getItem("quizCompleted_Level 3") &&
      localStorage.getItem("quizCompleted_Level 4") &&
      localStorage.getItem("quizCompleted_Level 5") &&
      localStorage.getItem("quizCompleted_Intro")
    ) {
      allQuizzesCompleted = true;
    }

    // for (let levelID = 1; levelID <= totalLevels; levelID++) {

    //   // let quizCompleted = localStorage.getItem(`quizCompleted_Level ${levelID}`);

    //   // if (!quizCompleted) {
    //   //   allQuizzesCompleted = false;
    //   //   break;
    //   // }
    // }

    if (allQuizzesCompleted) {
      localStorage.setItem("quizCompleted_Doors", true);
      // add class to js-qoutes-exit-screen in js-level-{currentLevelID}
      // document.querySelector(`.js-level-${localStorage.getItem("currentDoorId")} .js-qoutes-exit-screen`).classList.add("active");
      document.querySelector(".js-quiz-finish").classList.add("active");
    } else {
      localStorage.setItem("quizCompleted_Doors", false);
      // document.querySelector("js-quiz-finish").classList.add("remove");
    }

    this.nextButtonScreen.forEach((button) => {
      button.addEventListener("click", (e) => {
        _this.questionNumberEl.style.display = "block";
        _this.questionEl.style.display = "block";
        _this.questionTimer.style.display = "block";
        _this.questionContainer.style.display = "block";
        document.querySelector(".question__question-cta").style.display =
          "block";
        _this.questionInfo.style.display = "block";
        _this.resultContainer.style.display = "none";
        _this.resultContainer.parentElement.classList.remove("results");
        let avatarImg = document.querySelectorAll(".js-avatar-placeholder");
        avatarImg.forEach((item) => {
          item.classList.remove("deactive");
        });
      });
    });
  }

  isCompleted() {
    let isCompleted = localStorage.getItem("quizCompleted_Doors");

    if (isCompleted === "true") {
      document.querySelector(".js-quiz-finish").classList.add("active");
      console.log(isCompleted);
      return;
    }

    document.querySelector(".js-quiz-finish").classList.remove("active");
  }

  selectAnswer() {
    const answerElements = document.querySelectorAll(".question__answer-right");
    const answerArray = [...answerElements];
    answerArray.forEach((answer) => {
      answer.addEventListener("click", () => {
        answerArray.forEach((a) => {
          a.classList.remove("active");
        });
        answer.classList.add("active");
      });
    });
  }
  showNextQuestion() {
    let currentQuestionIndex = localStorage.getItem("currentQuestionIndex");
    let questions = JSON.parse(localStorage.getItem("questions"));

    let newCurrentQuestionIndex = Number(currentQuestionIndex) + 1;

    localStorage.setItem("currentQuestionIndex", newCurrentQuestionIndex);

    if (newCurrentQuestionIndex < questions.length) {
      this.showQuestion(questions[newCurrentQuestionIndex]);
    } else {
      this.showResult();
    }
  }

  timesUp() {
    this.validateAnswer();
    this.confirmButtonEl.disabled = true;
    this.nextButtonEl.disabled = false;
  }

  startTimer(duration) {
    this.stopTimer();
    this.duration = duration; // Assign duration to this.duration
    window.elapsedTime = 0; // Global variable for elapsed time

    window.timeout = setTimeout(() => {
      let time = this.duration; // Use this.duration instead of duration
      let i = 1;
      let k = (i / this.duration) * 100; // Use this.duration instead of duration
      let l = 100 - k;
      i++;
      document.getElementById("c1").style.strokeDasharray = [l, k];
      document.getElementById("c2").style.strokeDasharray = [k, l];
      document.getElementById("c1").style.strokeDashoffset = l;
      document.getElementById("js-timer").innerHTML = this.duration; // Use this.duration instead of duration

      window.intervalId = setInterval(() => {
        if (i > time) {
          this.timesUp();
          clearInterval(window.intervalId);
          clearTimeout(window.timeout);
          document.getElementById("js-timer").innerHTML = 0;
          return;
        }
        k = (i / this.duration) * 100; // Use this.duration instead of duration
        l = 100 - k;
        document.getElementById("c1").style.strokeDasharray = [l, k];
        document.getElementById("c2").style.strokeDasharray = [k, l];
        document.getElementById("c1").style.strokeDashoffset = l;
        document.getElementById("js-timer").innerHTML = this.duration + 1 - i;
        i++;

        window.elapsedTime++; // Increment the elapsed time global variable
      }, 1000);
    }, 1);
  }

  async validateAnswer() {
    let selectedInput = null;
    selectedInput = document.querySelector('input[name="answer"]:checked');
    let user = JSON.parse(localStorage.getItem("user"));
    let currentLevel = localStorage.getItem("currentLevel");
    let currentQuestion = localStorage.getItem("currentQuestion");

    let incorrectInputs = null;
    incorrectInputs = document.querySelectorAll(
      'input[type="radio"][data-correct="false"]'
    );
    let correctInputs = null;
    correctInputs = document.querySelector(
      'input[type="radio"][data-correct="true"]'
    );
    const player = this.lottieTrigger;
    function wrongAnswer() {
      incorrectInputs.forEach((input) => {
        const parentEl = input.closest(".js-answer");
        parentEl.classList.add("incorrect");
      });
    }

    if (selectedInput === null) {
      wrongAnswer();
      correctInputs.parentNode.classList.add("correct");
      this.questionInfo.classList.add("incorrect");
      this.questionInfo.textContent = "Odgovorili ste netačno!";
      this.nextButtonEl.disabled = false;
      this.confirmButtonEl.disabled = true;
      this.stopTimer();
    }

    if (selectedInput != null) {
      const correct = selectedInput.dataset.correct === "true";
      const selectedButton = selectedInput.parentElement;

      if (correct) {
        const levels = {
          1: "Intro",
          2: "Level 1",
          3: "Level 2",
          4: "Level 3",
          5: "Level 4",
          6: "Level 5",
        };
        this.score += 100;
        // increase score in db
        let res = await callApi(
          `${this.baseUrl}/update-employee-progress`,
          "POST",
          {
            progress_name: localStorage.getItem("currentDoorId")
              ? levels[localStorage.getItem("currentDoorId")]
              : levels[1],
            score: 100,
          },
          localStorage.getItem("access_token")
        );
        selectedButton.classList.add("correct");
        wrongAnswer();
        this.questionInfo.classList.add("correct");
        this.questionInfo.textContent = "Odgovorili ste tačno!!";
        this.lottieAnim();
        // Add bonus points
        if (window.intervalId !== null) {
          const elapsedTime =
            this.duration - (this.duration + 1 - window.elapsedTime);

          if (elapsedTime <= 15) {
            this.score += 30;
            // increase score in db
            let res = await callApi(
              `${this.baseUrl}/update-employee-progress`,
              "POST",
              {
                progress_name: localStorage.getItem("currentDoorId")
                  ? levels[localStorage.getItem("currentDoorId")]
                  : levels[1],
                score: 30,
              },
              localStorage.getItem("access_token")
            );
          }
        }

        // take question id and level and call db to save the progress...
        let response = await callApi(
          `${this.baseUrl}/update-employee-progress`,
          "POST",
          {
            progress_name:
              localStorage.getItem("level") !== "Doors"
                ? localStorage.getItem("level")
                : levels[localStorage.getItem("currentDoorId")],
            question: Number(currentQuestion) + 1,
            //score: this.score
          },
          localStorage.getItem("access_token")
        );

        localStorage.setItem("currentQuestion", Number(currentQuestion) + 1);
      } else {
        const levels = {
          1: "Intro",
          2: "Level 1",
          3: "Level 2",
          4: "Level 3",
          5: "Level 4",
          6: "Level 5",
        };
        selectedButton.classList.add("incorrect");
        wrongAnswer();
        correctInputs.classList.add("correct");
        this.questionInfo.classList.add("incorrect");
        this.questionInfo.textContent = "Odgovorili ste netačno!";
        // increase question by 1
        let res = await callApi(
          `${this.baseUrl}/update-employee-progress`,
          "POST",
          {
            progress_name: localStorage.getItem("currentDoorId")
              ? levels[localStorage.getItem("currentDoorId")]
              : levels[1],
            question: Number(currentQuestion) + 1,
          },
          localStorage.getItem("access_token")
        );
      }

      this.answerButtonsEl.querySelectorAll(".js-answer").forEach((button) => {
        if (
          button !== selectedButton &&
          button.querySelector('input[type="radio"]').dataset.correct === "true"
        ) {
          button.classList.add("correct");
        }

        button.disabled = true;
      });
      this.setCurrentScore(this.score);
      this.stopTimer();

      return true;
    }
  }
  lottieAnim() {
    const animItem = bodymovin.loadAnimation({
      wrapper: this.lottieTrigger,
      animType: "svg",
      loop: false,
      path: "https://assets2.lottiefiles.com/packages/lf20_lg6lh7fp.json",
    });
  }
  stopTimer() {
    clearInterval(window.intervalId);
    clearTimeout(window.timeout);
    window.intervalId = null;
    window.timeout = null;
  }

  setCurrentScore(score) {
    localStorage.setItem("currentScore", score);
  }

  getCurrentScore() {
    if (localStorage.getItem("currentScore")) {
      return localStorage.getItem("currentScore");
    }
  }

  screenExit() {
    let _this = this;
    this.screenExitBtn.addEventListener("click", async (e) => {
      let doors = document.querySelectorAll(".hall-level");
      doors.forEach((door) => {
        if (door.classList.contains("is-active")) {
          door.querySelector(".js-qoutes-exit-screen").style.display = "block";
        }
      });

      _this.screenExitContent.classList.add("active");
      document.querySelector(".js-intro-quiz").classList.remove("is-active");
      if (_this.screenExitContent.classList.contains("active")) {
        document.querySelectorAll(".js-avatar-placeholder").forEach((item) => {
          item.classList.add("deactive");
        });
      }
      if (this.screenExitContent.classList.contains("active")) {
        let avatarImgDeactivate = document.querySelectorAll(
          ".js-avatar-placeholder-deactivate"
        );
        avatarImgDeactivate.forEach((item) => {
          item.classList.remove("deactive");
        });
      }
    });
  }

  screenQoutesExit() {
    let _this = this;
    let activeContent = document.querySelector(
      ".hall-level.is-active .js-qoutes-exit-screen"
    );
    let activeContentTwo = document.querySelectorAll(".js-qoutes-reverse");
    let activeAvatar = document.querySelector(
      ".hall-level.is-active .js-avatar-placeholder"
    );
    this.screenExitBtn.addEventListener("click", () => {
      if (activeContent) {
        activeContent.classList.add("active");
        if (window.innerWidth < 768) {
          activeContentTwo.forEach((item) => {
            if (item.classList.contains("active")) {
              activeAvatar.classList.add("avatar-placeholder__image--reverse");
            }
          });
        }
      }
    });
  }

  backContentQuestion() {
    let _this = this;
    this.btnLevelDoor.forEach((item) => {
      item.addEventListener("click", () => {
        _this.questionNumberEl.style.display = "block";
        _this.questionEl.style.display = "block";
        _this.questionTimer.style.display = "block";
        _this.questionContainer.style.display = "block";
        document.querySelector(".question__question-cta").style.display =
          "block";
        _this.questionInfo.style.display = "block";
        _this.resultContainer.style.display = "none";
        _this.resultContainer.parentElement.classList.remove("results");
      });
    });
  }

  startGame() {
    let questions = JSON.parse(localStorage.getItem("questions"));
    let currentQuestionIndex = 0;
    localStorage.setItem("currentQuestionIndex", currentQuestionIndex);
    if (localStorage.getItem("currentScore")) {
      this.score = Number(localStorage.getItem("currentScore"));
    } else {
      this.score = 0;
      localStorage.setItem("currentScore", 0);
      localStorage.setItem("currentLevel", "initial");
      localStorage.setItem("level", "Intro");
    }
    this.questionContainer.style.display = "block";
    this.resultContainer.style.display = "none";
    this.backContentQuestion();
    this.showQuestion(questions[currentQuestionIndex]);
  }

  async getQuestions(categoryID, skip = false) {
    if (!categoryID) {
      return;
    }
    let response = await callApi(
      `${this.baseUrl}/get-questions-by-category-id?category_id=${categoryID}`,
      "GET",
      {},
      localStorage.getItem("access_token")
    );

    let responseFromServer = response.data;
    responseFromServer.forEach((item) => {
      item.answers = item.answers.filter((answer) => answer !== null);
    });
    let questions = responseFromServer;

    if (skip) {
      questions.splice(0, skip - 1);
    }

    localStorage.setItem("questions", JSON.stringify(questions));
    this.startGame();
  }
}
